define("ember-svg-jar/inlined/location-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M50 44.118c-7.377 0-13.279-5.883-13.279-13.236 0-7.353 5.902-13.235 13.279-13.235s13.279 5.882 13.279 13.235S57.377 44.118 50 44.118zM50 0C39.82 0 30.23 5 24.476 13.53c-5.754 8.382-6.935 19.117-3.246 28.676l14.016 30.882 12.098 25.294C47.787 99.412 48.82 100 50 100c1.18 0 2.213-.588 2.656-1.618l12.098-25.294L78.77 42.206c3.689-9.559 2.509-20.294-3.246-28.677C69.77 5 60.18 0 50 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});