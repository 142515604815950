define("ember-css-modules/helpers/local-class", ["exports", "require"], function (_exports, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.localClass = localClass;
  _exports.default = void 0;

  function localClass(params, hash) {
    (false && !('from' in hash) && Ember.assert('No source specified to local-class lookup', 'from' in hash));

    if (!hash.from) {
      return '';
    }

    var styles = resolveSource(hash.from);
    var classes = (params[0] || '').split(/\s+/);
    return classes.map(function (style) {
      return styles[style];
    }).filter(Boolean).join(' ');
  }

  var _default = Ember.Helper.helper(localClass);

  _exports.default = _default;

  function resolveSource(source) {
    if (typeof source === 'string') {
      if (_require.default.has(source)) {
        return (0, _require.default)(source).default;
      } else {
        throw new Error("Unable to resolve local class names from ".concat(source, "; does the styles file exist?"));
      }
    } else {
      return source;
    }
  }
});