define("ember-svg-jar/inlined/letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 15.152v69.696h100V15.152zm51.75 43.436c-1 .995-2.5.995-3.5 0l-37-35.969h77.625zm-19.875-8.961L7.5 74.02V25.855zm3.625 3.485l9.375 9.085c1.5 1.37 3.375 2.116 5.25 2.116s3.75-.747 5.25-2.116l9.375-9.085L89 77.38H11.125zm32.625-3.485L92.5 25.979v47.917z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});