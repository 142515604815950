define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M39.036 26.487c1-1 1.624-2.373 1.624-3.873 0-1.499-.624-2.873-1.624-3.873L21.794 1.624c-1-1-2.373-1.624-3.748-1.624-1.374 0-2.873.625-3.873 1.624l-1.874 1.874 24.863 24.864zM64.15 70.592c-.5.5-1.25.75-1.875.75s-1.374-.25-1.874-.75l-30.985-30.86c-.5-.5-.75-1.25-.75-1.875 0-.624.25-1.374.75-1.874l3.873-3.748L8.426 7.372l-4.123 4.123c-2.5 2.374-3.999 5.497-4.123 8.87-.375 5.623 1 10.995 2.873 16.243 2 4.623 4.623 8.995 7.497 13.119 10.245 15.367 23.114 28.736 38.107 39.356 2.498 1.874 5.122 3.498 7.871 4.998 6.247 3.123 12.869 5.372 19.99 5.872 4.748.375 9.496-1.5 12.744-4.998l3.374-3.373-24.738-24.864zM98.383 78.213L81.141 60.971a5.48 5.48 0 00-3.873-1.624c-1.5 0-2.873.625-3.873 1.624l-1.874 1.999 24.863 24.864 1.874-1.875c1-1 1.624-2.374 1.624-3.873s-.5-2.874-1.499-3.873z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});