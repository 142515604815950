define("memory-scroll/components/base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    memory: Ember.inject.service('memory-scroll'),

    didRender: function didRender() {
      this._super.apply(this, arguments);
      var key = this.get('key');
      if (!key) {
        throw new Error("You must provide a key to memory-scroll like {{memory-scroll key=\"my-awesome-pane\"}}.");
      }
      if (key !== this._lastKey) {
        this.remember(this._lastKey);
        this._lastKey = key;
        this.restore(key);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.remember(this.get('key'));
    },
    remember: function remember(key) {
      if (key) {
        var position = this.targetElement().scrollTop;
        this.get('memory')[key] = position;
      }
    },
    restore: function restore(key) {
      var position = this.get('memory')[key] || 0;
      var elt = this.targetElement();
      if (elt) {
        elt.scrollTop = position;
      }
    }
  });
});