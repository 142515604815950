define("ember-css-modules/templates/static-helpers-hack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3L7aXX8R",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,2,11,[27,[26,0,\"CallHead\"],[]],[\"hello\"],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "ember-css-modules/templates/static-helpers-hack.hbs"
    }
  });

  _exports.default = _default;
});